import React from 'react'
import '../styles/index.css'

//Components 
import Header from '../components/Header'

function Betingelser() {
  return (
    <>
      <Header />
      <div className='flex flex-col items-center h-screen py-10'>
        <div className='text-2xl text-center px-10 pb-10'>
          <p className='font-bold'>1. LEJENS BETALING OG REGULERING </p> <br />
          <p>1.1. Lejen forfalder forud til betaling hver den 1. i måneden,
            ved modtagelse af faktura fra Skovs lager. Lejen forudbetales for 1 måned ad gangen. </p> <br />
          <p>1.2. Lejen reguleres årligt den 1. januar, gældende for det følgende år.
            Omfattet af reguleringen er alene lejeforhold, som pr. 1. januar har løbet i mere end 6 måneder.
            Efter de første 6 måneders forløb reguleres lejeaftalen årligt. </p> <br />
          <p>1.3. Lejen reguleres med udgangspunkt i udviklingen i nettoprisindekset (oktober 2022 = 100). 
            Uanset den årlige udvikling i nettoprisindekset er det mellem parterne aftalt, at lejen pr. 1.
            januar hvert år som udgangspunkt forhøjes med minimum 2,5 % af foregående års leje og maksimalt 4 % af 
            foregående års leje. Dog er det mellem parterne aftalt, at lejens størrelse for en eksisterende lejer, 
            ikke kan overstige den normalleje (uden fradrag af eventuelle velkomstrabatter m.m.) som 
            en ny lejer kan leje et tilsvarende lagerrum for. </p> <br />
          <p>1.4. Skovs lager er til enhver tid berettiget til at trække et skyldigt beløb på det registrerede betalingskort. </p> <br />
          <p className='font-bold'> 2. OPSIGELSE </p>
          <p> 2.1. Lejemålet fortsætter indtil lejer opsiger lejemålet. Opsigelse kan ske med en måneds varsel til udgangen af 
            en måned, dog forudsat at rummet er helt tømt og hængelåsen fjernet. 
            Opsigelsen skal foretages skriftligt via vores mail: fsh@skovslager.dk </p> <br />
          <p> 2.2. Skovs lager kan opsige lejeaftalen, med en måneds varsel til udgangen af en måned. </p> <br /> 
          <p className='font-bold'>3. BENYTTELSE AF DET LEJEDE </p>
          <p> 3.1. Det lejede må kun benyttes til oplagring af lejers effekter, herunder effekter tilhørende medlemmer 
            af lejers husstand. Det lejede må ikke anvendes til beboelse eller længerevarende ophold for lejer eller 
            andre som lejer overlader adgangen til det lejede til. </p> <br />
          <p> 3.2. Farligt gods, herunder eksplosive og brandfarlige stoffer, genstande og væsker, bl.a. trykflasker, 
            og lignende effekter samt enhver form for våben eller dele heraf må ikke oplagres. 
            Alle former for mad og let fordærvelige eller ildelugtende produkter må ej heller oplagres. 
            Det er ikke tilladt at opbevare dyr i det lejede. Der må ikke opbevares gods, der på anden vis er eller 
            kan være skadelige eller miljøfarlige. Enhver overtrædelse af disse forbud medfører ophævelse af lejeaftalen. </p> <br />
          <p> 3.3. Ved oplagring af maskiner og køretøjer skal disse tømmes for olie, benzin og lignende væsker før oplagring. 
            Køleskabe, frysere, andre hårde hvidevarer og andre effekter, der indeholder væske eller stoffer, 
            der kan forårsage skade på lejemålet, skovs lagers ejendom, eller genstande i øvrige lejemål, må kun opbevares i 
            det lejede, såfremt de er forsvarligt indpakket eller drænet for væske. </p> <br />
          <p> 3.4. Såfremt lejer eller lejers genstande forårsager skader på lejemålet, skovs lagers ejendom, 
            eller andre lejeres genstande er lejer erstatningsansvarlig herfor. </p> <br />
          <p className='font-bold'>4. FORSIKRING AF DET OPBEVAREDE </p> <br />
          <p> 4.1. Vi har forsikret bygningen, men ikke dine ting. Du skal selv forsikre dine ejendele som du opbevarer hos os. 
            De er normalt dækket af din indboforsikring, men tjek med dit forsikringsselskab. </p> <br />
          <p className='font-bold'>5. ADGANG TIL DET LEJEDE </p> <br />
          <p> 5.1. Ved bortkomst af nøgle/hængelås skal lejer legitimere sig ved at forevise legitimation. </p> <br />
          <p> 5.2. Adgangen til det lejede foregår via døre, der betjenes af lejer ved brug af kode eller kodebrik. 
            Skovs lager kan ikke gøres erstatningspligtig for tab eller lignende, der måtte opstå som følge af pludseligt 
            opståede svigt i de automatiske døre, herunder fx i forbindelse med strømsvigt. </p> <br />
          <p> 5.3. Der er adgang til dit depotrum 365 dage om året fra kl.6 til kl.22. Ved adgang uden for dette tidsrum, 
            kan lejer kontakte os i hverdage mellem kl. 7 -16 for aftale. </p> <br />
          <p className='font-bold'>6. OVERTAGELSE OG FORANDRINGER </p> <br />
          <p> 6.1. Det lejede overtages som besigtiget af lejer uden mangler, idet det bemærkes, at det lejede ikke ved indflytning 
            er ny-istandsat. </p> <br />
          <p> 6.2. Lejer er uberettiget til at foretage ændringer af eller installationer i det lejede. Der må ikke bores i loft, 
            væg eller gulv. </p> <br />
          <p className='font-bold'> 7. FÆRDSEL PÅ EJENDOMMENS OMRÅDE </p> <br />
          <p> 7.1. færdsel på det videoovervågede området uden for bygninger er på eget ansvar, Rygning og brug af rusmidler 
            er strengt forbudt på skovs lagers område. </p> <br />
          <p> 7.2. Det er forbudt at fjerne transportvogne fra ejendommens offentligt tilgængelige område, herunder er det forbudt, 
            at låse vogne, palleløftere m.m. ind i det af kunden lejede opbevaringsrum. Fjernelse af transportvogne 
            eller andet materiel fra skovs lagers ejendomme vil blive betragtet som tyveri, og skovs lager vil 
            foretage politianmeldelse heraf på baggrund af foretagne videooptagelser. </p> <br />
          <p className='font-bold'>8. AFLEVERING AF LEJEMÅL </p> <br />
          <p> 8.1. Ved lejemålets ophør skal det lejede afleveres i samme gode stand som ved overtagelsen. 
            Såfremt det lejede på opsigelsestidspunktet ikke forefindes i den stand som angivet ovenfor under pkt. 6, 
            er skovs lager berettiget til at istandsætte det lejede for lejers regning. Lejer er endvidere pligtig til at 
            betale leje i istandsættelsesperioden. </p> <br />
          <p> 8.2. Ved lejers fraflytning er lejer forpligtiget til at rydde depotrummet og rengøre dette. 
            Såfremt lejer ikke på fraflytningsdagen har opfyldt denne forpligtigelse, er skovs lager berettiget til at tømme og rengøre 
            det lejede for lejers regning. Lejer er forpligtiget til at betale de faktiske udgifter i forbindelse med rydning og 
            rengøring, dog minimum kr. 500. </p> <br />
          <p> 8.3. Efterladte effekter, der henstår i det lejede efter lejers opsigelse og fraflytning, anses for uden værdi og 
            fjernes på lejers bekostning uden forudgående varsel. Tilsvarende gælder for effekter, som lejer måtte have efterladt 
            udenfor det lejede, på udlejers offentligt tilgængelige arealer. </p> <br />
          <p className='font-bold'>9. SKOVS LAGERS ANSVAR OG ADGANG TIL DET LEJEDE </p> <br />
          <p> 9.1. Opbevaring i det lejede sker for lejers regning og risiko. Skovs lager er ikke ansvarlig for, at lejer får 
            tab, der måtte opstå som følge af, at lejers opbevarede effekter bliver beskadiget ved brand, tyveri, 
            skadedyrsangreb, vandskade, stormskade, hærværk mv. Skovs lager kan endvidere ikke gøres ansvarlig for lejers tab i 
            form af tidstab, driftstab, avancetab og andre indirekte tab. </p> <br />
          <p> 9.2. Skovs lager eller dennes stedfortræder har ret til at få eller skaffe sig adgang til det lejede, når forholdene 
            kræver det, herunder hvis der opstår fare for skader eller lækage, samt med henblik på udnyttelse af sine rettigheder 
            i forhold til lejers misligholdelse. Skovs lager har ligeledes ret til adgang til det lejede efter 14 dages 
            forudgående skriftligt varsel over for lejer med henblik på eftersyn, reparation, vedligeholdelse mv. af det lejede. </p> <br />

          <p> 9.3. Det påhviler lejeren til enhver tid at oplyse skovs lager om gældende postadresse og e- mailadresse, 
            hvortil meddelelser i henhold til forretningsbetingelser og lovgivningen kan fremsendes samt gældende telefonnummer, 
            hvorpå lejeren kan træffes. Hvis lejer ikke har oplyst gældende postadresse, e-mailadresse og telefonnummer til 
            skovs lager, er konsekvensen for lejer, at denne bærer risikoen for, at lejer ikke får meddelelse i henhold til 
            forretningsbetingelser og lovgivning tids nok til at overholde de i lovgivningen fastsatte frister. </p> <br />
          <p className='font-bold'>10. INKASSO OG OPHÆVELSE VED MANGLENDE BETALING </p> <br />
          <p> 10.1. Ved manglende betaling vil lejer modtage påmindelser pr. SMS og mail, til det telefonnummer og den mailadresse, 
            som lejer har oplyst til skovs lager. Dernæst sender skovs lager påkrav om betaling af skyldig leje, og der vil i 
            den forbindelse blive pålagt gebyr (påkravsgebyr) for manglende betaling af leje. Hvis lejer fortsat ikke betaler det 
            skyldige beløb, vil det blive overdraget til retslig inkasso og fogeden om nødvendigt blive inddraget. </p> <br />
          <p> 10.2. Skovs lager kan hæve lejeaftalen og lejer er forpligtet til at betale udgifterne i forbindelse med ophævelse 
            og evt. udsættelse af det lejede, herunder i nedenstående tilfælde: <br />
          • Når leje eller anden pligtig pengeydelse ikke er betalt rettidigt. <br />
          • Når det lejede benyttes til andet end aftalt og lejeren ikke trods Skovs lagers indsigelse er
          ophørt hermed. <br />
          • Når lejeren modsætter sig, at Skovs lager eller andre får adgang til det lejede, hvor de har ret hertil. <br />
          • Når lejeren fraflytter det lejede i utide uden aftale med Skovs lager. <br />
          • Når lejeren vanrøgter det lejede. <br />
          • Når lejeren helt eller delvis overlader brugen af det lejede til en anden i tilfælde, hvor lejeren ikke er berettiget hertil. <br />
          • Når lejeren i øvrigt misligholder sine forpligtelser på en sådan måde, at lejerens fjernelse er påkrævet. <br /> </p> <br />
          <p className='font-bold'>11. BESTEMMELSE OG HENVISNINGER I ØVRIGT </p> <br />
          <p>11.1. Ved at acceptere forretningsbetingelserne i forbindelse med bookning af det lejede accepterer lejer, at 
            Skovs lager må henvende sig til lejer via SMS og e-mail med enhver information og korrespondance relateret til det 
            indgåede lejemål. </p> <br />
          <p className='font-bold'> 12. FORTRYDELSESRET </p> <br />
          <p> 13.1. Fjernsalg i Danmark er omfattet af 14 dages fortrydelsesret, jf. forbrugeraftaleloven. 
            Ved leje af lagerrum hos Skovs lager, indgås aftalen med forbrugeren på Skovs lagers faste forretningssted, 
            hvorfor reglerne om fortrydelsesretten ved fjernkøb i forbrugeraftaleloven ikke finder anvendelse. 
            Med oprettelsen af din lejeaftale er der indgået en bindende lejeaftale, hvoraf der ikke er fortrydelsesret. 
            For opsigelse se punkt 2. </p> <br />
          <p className='font-bold'>14. KLAGEMULIGHED </p> <br />
          <p> 14.1. Hvis du som forbruger vil klage over dit køb, skal du kontakte Skovs lager på mail fsh@skovslager.dk
            </p>
          </div>
      </div>
    </>
  )
}

export default Betingelser